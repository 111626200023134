import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage3Avaleht from "./pages/Webpage3Avaleht";
import Webpage16Isikuandmete_t____tlemine_kandideerimisel from "./pages/Webpage16Isikuandmete_t____tlemine_kandideerimisel";
import Webpage17T____tamine_IT_Projektis from "./pages/Webpage17T____tamine_IT_Projektis";
import Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt from "./pages/Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt";
import Webpage19Backend_arendaja_t____kuulutus___IT_Projekt from "./pages/Webpage19Backend_arendaja_t____kuulutus___IT_Projekt";
import Webpage21Home from "./pages/Webpage21Home";
import Webpage22Teenused from "./pages/Webpage22Teenused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiMDA5NDdiYzVmNDIxNjE2ZWI1NGZhMjRiMTMxMmY1YTNiM2Y5OTU4ZTM0NDc1MmMzMDg2OWRiMjYwNDFhZTliMjZhZjdhMTIwNzM2MTRkOTYiLCJpYXQiOjE3NDQyNjk1OTkuNTQxMTQxLCJuYmYiOjE3NDQyNjk1OTkuNTQxMTQ3LCJleHAiOjE3NDQzNTU5OTkuNTM3MTEyLCJzdWIiOiI2MyIsInNjb3BlcyI6W119.XfFt5ggqruCcQpPEMTESsbDZ4dmIr-1hTCVUKBNd36wbgmGR1NaOhWzplgav8Yc1qRoiETdc79maGaM-HuIcOTAM_0vuivEUYE-BKzcipvzEROLHC85toWc3QJYsFUWwtPyIG3No9wohU1nLuciBVdDbzKQmp9zIhKzSGvbBEbRxZkK7sXSTY47gAoeQ-f13_7AmA8CtRJvyP6zJOQfkKgtuSuwIyitEP8k98JWLarwenaDT-NrlYlDk37rozjTL1j5FZCN_ROOJyuBn0uC-oZf_t782Xt9xXXbp30XCQvCMATpCGhJkxOoi4izGBeR8s2CxacBKpA_GIGRN97yGVI2hbcTOaWOC-mV55HQl9bPN4Bq1JbbID0ucYFLpm7VwBBocqRiVzLplUldCkxTyOqMqHE7Xr5zVj7XZ-aciXAkYRyBnVNCm_OTAo8qxgAWsiLSqcCJj1BdLUwr_r4bNR_P5MonThNdt8nJXTKhiZ5wcv3A2nWamzXFbEF-tFBRyiA7jt4dsy2nU04t8lzXmkV_ivkoWVn92lXJFrZv-GyHNj5hezbr7yHCqOnb2Gn4b8njpH5zamxYVejDFtkxBTVRNYlsS_KYmVP-uWWoAKCbANWJTptAfy0FjY2IsN-3sxg6ktn23E69pxX_JcSeJlp2cKhKKBh0FaesGRG0xXf8',
    'Access-api-key': "eyJpdiI6Ilh2VkhHd20wMFYrSlJTSFh6ZDBuQlE9PSIsInZhbHVlIjoidi9MTnMxb09YZEdwWjRtK2ZrSGVwdz09IiwibWFjIjoiMTVlODA1YjNhYTgxNDc3MGZhZjUwMDY1NTk0Y2YwMjUxNTU2YmYyMTM2YmY4NmU3YWZhNDQ4MmNhMzZkYjc4YSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IjJGZkpuMWVlOHhkbVQzUnVtTEpLYkE9PSIsInZhbHVlIjoiM0tJU3ZQZDEvdmtnY3VDL3JGVmdJUT09IiwibWFjIjoiYzg4MDc1NWU3OWU3Njg2YjMyNDJkNDdkMmIxZDQxNmU4ZGM4MjYwY2Y1NWRlYjNhOGNhZmQ0NjZmNDJlYjc1OCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-MXH2B4"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage3Avaleht />} exact />;
                                                                    <Route path="/et/isikuandmed" element={<Webpage16Isikuandmete_t____tlemine_kandideerimisel />} exact />;
                                                                    <Route path="/et/tootamine-it-projektis" element={<Webpage17T____tamine_IT_Projektis />} exact />;
                                                                    <Route path="/et/full-stack" element={<Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt />} exact />;
                                                                    <Route path="/et/backend" element={<Webpage19Backend_arendaja_t____kuulutus___IT_Projekt />} exact />;
                                                                    <Route path="/en/" element={<Webpage21Home />} exact />;
                                                                    <Route path="/et/teenused" element={<Webpage22Teenused />} exact />;
                                                                
                                            <Route path="itprojekt.ee/et/isikuandmed" element={<Redirect to="itprojekt.ee/et/tootamine-it-projektis/isikuandmed" />} exact />;
                                                                <Route path="*" element={<Webpage3Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}